import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import axios from "axios";
import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";

const USER_GET = gql`
  query($email: String!){
  userByEmail(email: $email) {
    id
    uid
  }
}
`;

function ResetPassword() {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [userProfile, setUserProfile] = useState(null);
  const [isPending, setIsPending] = useState(false);

  const [getUser, { loading, error: userError, data }] = useLazyQuery(USER_GET);

  const filterText = (str) => {
    let txt = str
      .replaceAll(/Firebase:|Error|Auth/gi, "")
      .replace(/[^a-zA-Z ]/gi, " ")
      .trim();
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  };

  const handleInputForm = (e) => {
    setEmail((prevData) => {
      return { ...prevData, [e.target.name]: e.target.value };
    });
  };

  const handleReset = () => {

    let data = JSON.stringify({
      "subject": "Forget Password",
      "fromEmail": "info@messageapi.in",
      "toEmail": email.email,
      "html": `<!doctype html>
    <html lang="en-US">

    <head>
      <meta content="text/html; charset=utf-8" http-equiv="Content-Type" />
      <title>Reset Password Email Template</title>
      <meta name="description" content="Reset Password Email Template.">
      <style type="text/css">
        a:hover {
          text-decoration: underline !important;
        }
      </style>
    </head>

    <body marginheight="0" topmargin="0" marginwidth="0" style="margin: 0px; background-color: #f2f3f8;" leftmargin="0">
      <table cellspacing="0" border="0" cellpadding="0" width="100%" bgcolor="#f2f3f8" style="@import url(https://fonts.googleapis.com/css?family=Rubik:300,400,500,700|Open+Sans:300,400,600,700); font-family: 'Open Sans', sans-serif;">
        <tr>
          <td>
            <table style="background-color: #f2f3f8; max-width:670px;  margin:0 auto;" width="100%" border="0" align="center" cellpadding="0" cellspacing="0">
              <tr>
                <td style="height:80px;">&nbsp;</td>
              </tr>
              <tr>
                <td style="text-align:center;">
                  <a href="https://messageapi.in/" title="logo" target="_blank">
                    <img width="60" src="https://messageapi.in/static/media/logo.387d1a61bf25df4c2242.png" title="logo" alt="logo">
                  </a>
                </td>
              </tr>
              <tr>
                <td style="height:20px;">&nbsp;</td>
              </tr>
              <tr>
                <td>
                  <table width="95%" border="0" align="center" cellpadding="0" cellspacing="0" style="max-width:670px;background:#fff; border-radius:3px; text-align:center;-webkit-box-shadow:0 6px 18px 0 rgba(0,0,0,.06);-moz-box-shadow:0 6px 18px 0 rgba(0,0,0,.06);box-shadow:0 6px 18px 0 rgba(0,0,0,.06);">
                    <tr>
                      <td style="height:40px;">&nbsp;</td>
                    </tr>
                    <tr>
                      <td style="padding:0 35px;">
                        <h1 style="color:#1e1e2d; font-weight:500; margin:0;font-size:32px;font-family:'Rubik',sans-serif;">You have
                          requested to reset your password</h1>
                        <span style="display:inline-block; vertical-align:middle; margin:29px 0 26px; border-bottom:1px solid #cecece; width:100px;"></span>
                        <p style="color:#455056; font-size:15px;line-height:24px; margin:0;">
                          A unique link to reset your
                          password has been generated for you. To reset your password, click the
                          following link and follow the instructions.
                        </p>
                        <a href="https://reseller.messageapi.in/changepassword?uid=${userProfile.uid}&fid=${userProfile.id}" style="background:#20e277;text-decoration:none !important; font-weight:500; margin-top:35px; color:#fff;text-transform:uppercase; font-size:14px;padding:10px 24px;display:inline-block;border-radius:50px;">Reset
                          Password</a>
                      </td>
                    </tr>
                    <tr>
                      <td style="height:40px;">&nbsp;</td>
                    </tr>
                  </table>
                </td>
              <tr>
                <td style="height:20px;">&nbsp;</td>
              </tr>
              <tr>
                <td style="text-align:center;">
                  <p style="font-size:14px; color:rgba(69, 80, 86, 0.7411764705882353); line-height:18px; margin:0 0 0;">&copy; <strong>messageapi.in</strong></p>
                </td>
              </tr>
              <tr>
                <td style="height:80px;">&nbsp;</td>
              </tr>
            </table>
          </td>
        </tr>
      </table>
    </body>

    </html>`,
      "attachments": []
    });

    let config = {
      method: 'post',
      url: 'https://messageapi.in/mail/sendmail',
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        setSuccess("Reset mail sent successfully");
        setIsPending(false);
      })
      .catch((error) => {
        console.log(error);
        setError(error.message);
      });
  };

  useEffect(() => {
    let userProfile = null;
    if (data) {
      userProfile = data.userByEmail;
      setUserProfile(userProfile);
    }
  }, [data, loading]);

  useEffect(() => {
    if (userProfile) {
      handleReset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userProfile])

  useEffect(() => {
    if (userError && userError.message !== "") {
      setError("Email not found")
      setIsPending(false)
    }
  }, [userError])

  return (
    <div className="flex flex-col h-screen w-screen justify-center items-center">
      {success ? (
        <p className="text-xl text-green-600 text-center">
          {filterText(success)}
        </p>
      ) : (
        <p className="text-xl text-red-600 text-center">{filterText(error)}</p>
      )}

      <div className="flex flex-col w-1/2  bg-gray-400 rounded-lg p-4">
        <h1 className="text-xl mb-2 text-white text-center">Reset Password</h1>
        <hr className="mb-6" />
        <div className="mb-8">
          <label
            htmlFor="email"
            className="text-xl font-semibold mr-2 block mb-2"
          >
            Email:
          </label>
          <input
            type="email"
            name="email"
            id="email"
            className="bottom-2 border-gray-500 p-2 rounded-md w-full"
            onChange={handleInputForm}
          />
        </div>
        <div className="w-full text-right flex justify-between">
          <Link
            to="/login"
            className="bg-green-400 text-white rounded-md py-2 px-8"
          >
            Home
          </Link>
          {
            !isPending && (
              <button
                onClick={() => {
                  setIsPending(true);
                  getUser({ variables: { email: email.email } })
                }
                }
                className="bg-yellow-400 text-white rounded-md py-2 px-8"
              >
                Reset
              </button>
            )
          }
          {
            isPending && (
              <button
                disabled
                className="bg-yellow-200 text-gray-400 rounded-md py-2 px-8"
              >
                Wait...
              </button>
            )
          }
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;
